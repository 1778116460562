import * as Yup from "yup";
import * as installationApi from "../../api/installation";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import PageActions, {
	LeftActions,
	RightActions,
} from "../../components/layout/PageActions";
import { useContext, useEffect, useState } from "react";
import { useFetchData, usePermissions } from "../../hooks";
import Accordion from "../../components/layout/Accordion";
import Alert from "react-s-alert";
import { AppContext } from "../../context/app-context";
import Button from "../../components/layout/Button";
import Card from "../../components/layout/Card";
import Dropdown from "../../components/layout/Dropdown";
import EditableInputField from "../../components/layout/EditableInputField";
import FormLayout from "../../components/layout/FormLayout";
import LoadingPlaceholder from "../../components/report/LoadingPlaceholder";
import PageTitle from "../../components/layout/PageTitle";
import { AlertCircle, Plus } from "react-feather";
import RateTesterWizard from "../../components/wizards/rate-tester-wizard";
import RateWizard from "../../components/wizards/rates-wizard";
import { isEqual } from "lodash";
import moment from "moment";
import styled from "styled-components";
import { useGetAllEventSeries } from "./queries/eventsApiHooks";
import {
	DURATION_CONFIG,
	DURATION_CONVERTERS,
	DURATION_MAXIMUM_RESTRICTION_CONFIG,
	DURATIONS,
	durationTimeInMinutes,
	durationTypes,
} from "../../helpers/constants";

moment.updateLocale("en", {
	week: {
		dow: 1,
		doy: 1,
	},
});

const localizer = momentLocalizer(moment);

const FieldWrapper = styled.div`
	margin: 16px 32px;
`;

const FieldContent = styled.div`
	> * {
		margin: 0;
		max-width: 600px;
		min-height: 32px;
	}
`;

const Label = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 4px;
`;

const SubLabel = styled.div`
	font-size: 14px;
	margin-bottom: 4px;
	font-weight: 700;
	color: #5e5e5e;
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: ${(props) =>
		props.$sessionDuration
			? "repeat(3, minmax(0, 1fr))"
			: "repeat(2, minmax(0, 1fr))"};
	gap: ${(props) => (props.$sessionDuration ? "0rem" : "1rem")};
`;

const DurationWrapper = styled.div`
	display: grid;
	grid-template-columns: 0.2fr 1fr 1fr;
	align-items: start;
	gap: 0.5rem;
`;

const IncrementWrapper = styled.div`
	display: grid;
	grid-template-columns: 0.5fr 1 fr;
	align-items: start;
`;

const ErrorMessage = styled.div`
	gap: 0.5rem;
	align-items: center;
	font-size: 0.8rem;
	display: flex;
	color: rgb(208, 74, 81);
`;

const convertToMinutes = ({ duration, durationType }) => {
	const converter =
		DURATION_CONVERTERS[durationType] || DURATION_CONVERTERS.default;
	return converter(+duration);
};

const getFilteredDurationTypes = (durationType, minimumRestrictionType) => {
	if (durationType?.value === "Day") {
		return durationTypes.filter((e) => !["Minutes", "Hours"].includes(e.value));
	}
	if (minimumRestrictionType?.value === "Day") {
		return durationTypes.filter((e) => !["Minutes"].includes(e.value));
	}
	return durationTypes;
};

export default function RatesContainer(props) {
	const [state, setState] = useState({
		initialLoad: true,
		groups: [],
		events: null,
		rates: [],
		currentDate: new Date(),
		rateWizardOpen: false,
		rateTesterWizardOpen: false,
		mode: "add",
	});

	const [errorMessage, setErrorMessage] = useState({
		incrementError: null,
		minimumError: null,
	});

	const dayIndex = {
		Mon: 0,
		Tue: 1,
		Wed: 2,
		Thu: 3,
		Fri: 4,
		Sat: 5,
		Sun: 6,
	};
	const calendarColors = {
		Complex: "#d04a51", // Red
		EarlyBird: "#4a90e2", // Blue
		Daytime: "#4a90e2", // Blue
		Validation: "#f0ad4e", // Orange
		Group: "#000", // Black
		Event: "#d04a51", // Red
		EventSeries: "#8e44ad", // Purple
		EventSeriesBooking: "#27ae60", // Green //Place holder, not currently in use
	};

	const canManageRates = usePermissions(null, "ManageRates", true);

	function getEventByDay(day, rate, startOfWeek) {
		let events = [];
		let isOpen = false;
		let start;
		let end;

		const date = startOfWeek.clone().add(dayIndex[day], "days");
		const beginingOfTheDay = moment(
			date.format("DD/MM/YYYY 00:00"),
			"DD/MM/YYYY HH:mm"
		);

		if (rate.RateClass === "Complex") {
			const enterOnDay = dayIndex[rate.EnterOnDay];
			const existOnDay = dayIndex[rate.ExitOnDay || rate.ExitOnOrBeforeDay];
			const currentDayIndex = dayIndex[day];

			//if the rate wrap two weeks
			if (enterOnDay > existOnDay) {
				isOpen =
					(currentDayIndex >= enterOnDay && currentDayIndex <= 6) ||
					(currentDayIndex >= 0 && currentDayIndex <= existOnDay);
			} else {
				isOpen = currentDayIndex >= enterOnDay && currentDayIndex <= existOnDay;
			}

			if (isOpen) {
				//if the advance rate enter and exist on the same day
				if (enterOnDay === currentDayIndex && existOnDay === currentDayIndex) {
					start = beginingOfTheDay.clone().add(rate.EntryLaterThan, "seconds");
					end = beginingOfTheDay.clone().add(rate.ExitEarlierThan, "seconds");
				}
				//if the rate enter on current day but exit on a different day
				if (enterOnDay === currentDayIndex && existOnDay !== currentDayIndex) {
					start = beginingOfTheDay.clone().add(rate.EntryLaterThan, "seconds");
					end = beginingOfTheDay.clone().add(86340, "seconds");
				}
				//if the rate endter on a different day but exit on current day
				if (enterOnDay !== currentDayIndex && existOnDay === currentDayIndex) {
					start = beginingOfTheDay;
					end = beginingOfTheDay.clone().add(rate.ExitEarlierThan, "seconds");
				}
				//if the rate endter and exit on a different day
				if (enterOnDay !== currentDayIndex && existOnDay !== currentDayIndex) {
					start = beginingOfTheDay;
					end = beginingOfTheDay.clone().add(86340, "seconds");
				}
			}
		} else {
			isOpen = rate[`${day}`];
			start = beginingOfTheDay.clone().add(rate[`${day}Start`], "seconds");
			const endSeconds = rate[`${day}End`];
			end = beginingOfTheDay.clone().add(endSeconds, "seconds");
		}

		if (rate.IsValidationRate) {
			rate.RateClassForCSS = "Validation";
		} else if (rate.CachedOrganizationAccessGroupIDs) {
			rate.RateClassForCSS = "Group";
		} else {
			rate.RateClassForCSS = rate.RateClass;
		}

		if (isOpen) {
			events = getEvent(rate, start, end);
		}

		// fix rates that wrap overnight from sunday to monday
		if (
			day === "Mon" &&
			rate[`${day}Start`] > rate[`${day}End`] &&
			rate.Sun === 1
		) {
			isOpen = 1;
			start = beginingOfTheDay;
			end = beginingOfTheDay.clone().add(rate[`${day}End`], "seconds");
			events = [...events, ...getEvent(rate, start, end)];
		}

		return events;
	}

	function getEvent(rate, start, end) {
		let event;
		if (start > end) {
			event = [
				{
					start: start.toDate(),
					end: start.clone().endOf("day").toDate(),
					endTime: start.clone().add(1, "day").startOf("day").toDate(),
					title: rate.Name,
					hexColor: calendarColors[rate.RateClassForCSS],
					rate,
				},
				{
					start: start.clone().add(1, "day").startOf("day").toDate(),
					end: end.clone().add(1, "day").toDate(),
					endTime: end.toDate(),
					title: rate.Name,
					hexColor: calendarColors[rate.RateClassForCSS],
					rate,
				},
			];
		} else {
			const clone = end.clone();
			let time = clone.format("HH:mm");
			//to fill an event to the end of the day in BigCalendar, the dates need to be from StartDate to 23:59
			//if we used 00:00 then BigCalendar will recognise that as an overlapping range and will try to display
			//the event on both days, which is visually incorrect for what we want to convey to the end user
			//if the end time is 00:00, we'll reduce it to 23:59 for display purposes
			if (time === "00:00") {
				time = "23:59";
			}
			event = [
				{
					start: start.toDate(),
					end: start
						.clone()
						.startOf("day")
						.add(moment.duration(time).asSeconds(), "seconds")
						.toDate(),
					endTime: end.toDate(),
					title: rate.Name,
					hexColor: calendarColors[rate.RateClassForCSS],
					rate,
				},
			];
		}

		return event;
	}

	function eventStyleGetter(event) {
		var backgroundColor = event.hexColor;
		var style = {
			backgroundColor: backgroundColor,
			borderRadius: "0px",
			opacity: 0.8,
			color: "white",
			border: "0px",
			display: "block",
		};
		return {
			style: style,
		};
	}

	function getWeeklyEvents(start, rates) {
		let events = [];

		const startOfWeek = moment(start).startOf("isoWeek");

		for (const rate of rates) {
			const eventsForRate = [
				...getEventByDay("Mon", rate, startOfWeek),
				...getEventByDay("Tue", rate, startOfWeek),
				...getEventByDay("Wed", rate, startOfWeek),
				...getEventByDay("Thu", rate, startOfWeek),
				...getEventByDay("Fri", rate, startOfWeek),
				...getEventByDay("Sat", rate, startOfWeek),
				...getEventByDay("Sun", rate, startOfWeek),
			];

			if (rate.RateClass === "Event" || rate.RateClass === "EventSeries") {
				const startDate = moment(rate.StartDate, "YYYYMMDDHHmm");
				const endDate = moment(rate.EndDate, "YYYYMMDDHHmm");
				//if the duration spans over to the next day, display two events
				if (startDate.clone().endOf("day") < endDate) {
					const days =
						endDate
							.clone()
							.startOf("days")
							.diff(startDate.clone().startOf("days"), "days") - 1;
					eventsForRate.push({
						start: startDate.toDate(),
						end: startDate.clone().endOf("day").toDate(),
						title: rate.Name,
						hexColor: calendarColors[rate.RateClassForCSS],
						rate,
					});

					if (days > 0) {
						Array(days)
							.fill(0)
							.forEach(() => {
								const currentDay = startDate.add(1, "day");
								eventsForRate.push({
									start: currentDay.startOf("day").toDate(),
									end: currentDay.endOf("day").toDate(),
									title: rate.Name,
									hexColor: calendarColors[rate.RateClassForCSS],
									rate,
								});
							});
					}

					eventsForRate.push({
						start: endDate.clone().startOf("day").toDate(),
						end: endDate.toDate(),
						title: rate.Name,
						hexColor: calendarColors[rate.RateClassForCSS],
						rate,
					});
				} else {
					eventsForRate.push({
						start: startDate.toDate(),
						end: endDate.toDate(),
						title: rate.Name,
						hexColor: calendarColors[rate.RateClassForCSS],
						rate,
					});
				}
			}

			events = events.concat(eventsForRate.filter((e) => e));
		}

		setState((_state) => ({
			..._state,
			events: events,
			currentDate: start,
		}));
	}

	const organizationId = props.selectedOrganization
		? props.selectedOrganization.OrganizationID
		: null;
	const SessionConfigurationAccess =
		props?.selectedOrganization?.SessionConfigurationAccess ?? false;

	const {
		data: rates, //We really need to time restrict this, as we can have hundreds of rates return now
		isLoading,
		initialFetchComplete: ratesLoaded,
	} = useFetchData(
		[], //initialState
		installationApi.getCurrentRatesBySiteId, //endpoint
		[props.selectedSite ? props.selectedSite.SiteID : null], //params or options
		[props.selectedSite, state.rates, state.groupId] //dependancy array
	);

	const { eventSeries } = useGetAllEventSeries(true);

	const { data: beacons } = useFetchData(
		[],
		installationApi.getValidationBeaconsBySiteId,
		[props.selectedSite ? props.selectedSite.SiteID : null],
		[props.selectedSite, state.rates, state.groupId]
	);

	const { data: groups, initialFetchComplete: groupsLoaded } = useFetchData(
		[],
		installationApi.getAccessGroupsAssignedToSite,
		[organizationId, props.selectedSite ? props.selectedSite.SiteID : null],
		[props.selectedSite]
	);

	const {
		dispatch: { getAvailableSites, setSite },
	} = useContext(AppContext);

	useEffect(() => {
		getAvailableSites();
	}, [props?.selectedSite?.SiteID]);

	const byGroup = (rate) => {
		const foundGroup = JSON.parse(
			rate.CachedOrganizationAccessGroupIDs || "[]"
		).includes(state.groupId);
		if (state.groupId || rate.CachedOrganizationAccessGroupIDs) {
			if (state.groupId && !foundGroup) {
				return false;
			}
			return foundGroup;
		}

		return true;
	};
	const ratesForGroup = rates.filter(byGroup);
	if (
		ratesLoaded &&
		!isEqual(
			state.rates.map((r) => r.RateID),
			ratesForGroup.map((r) => r.RateID)
		)
	) {
		getWeeklyEvents(state.currentDate, ratesForGroup);
		setState((_state) => ({
			..._state,
			rates: ratesForGroup,
		}));
	}

	const mappedGroups = groups.map((group) => ({
		label: group.Name,
		value: group.OrganizationAccessGroupID,
	}));
	if (groupsLoaded && !isEqual(state.groups, mappedGroups)) {
		setState((_state) => ({
			..._state,
			groups: mappedGroups,
		}));
	}

	useEffect(() => {
		const events = document.getElementsByClassName("rbc-event");
		for (let event of events) {
			const content = event.getElementsByClassName("rbc-event-content")[0];
			if (content) {
				const span = content.getElementsByTagName("span")[0];
				if (span) {
					const attributes = JSON.parse(span.dataset.event);
					if (moment(attributes.event.endTime).format("HH:mm") === "00:00") {
						//should display as 12:00 AM
						const label = event.getElementsByClassName("rbc-event-label")[0];
						const match = " — 11:59 PM";
						if (label && label.innerText.includes(match)) {
							label.innerText = label.innerText.replace(match, " - 12:00 AM");
						}
					}
				}
			}
		}
	});

	function openRateWizard(rate, mode) {
		if (props.selectedSite) {
			setState((_state) => ({
				..._state,
				rateWizardOpen: true,
				rate,
				mode,
			}));
		} else {
			Alert.error("Please select a site");
		}
	}

	function openRateTesterWizard() {
		if (props.selectedSite) {
			setState((_state) => ({
				..._state,
				rateTesterWizardOpen: true,
			}));
		} else {
			Alert.error("Please select a site");
		}
	}

	if (state.rateWizardOpen) {
		return (
			<RateWizard
				close={() => {
					setState((_state) => ({
						..._state,
						rateWizardOpen: false,
						rates: [],
					}));

					getAvailableSites();
				}}
				remove={() => openRateWizard(state?.rate, "remove")}
				site={props.selectedSite}
				mode={state.mode}
				rate={state.rate}
				groups={state.groups}
				canManageRates={canManageRates}
				//convert eventSeries to format label, value
				eventSeries={eventSeries?.map((series) => ({
					...series,
					value: series.EventSeriesID,
					label: series.Name,
				}))}
			/>
		);
	}

	if (state.rateTesterWizardOpen) {
		return (
			<RateTesterWizard
				close={() => {
					setState((_state) => ({
						..._state,
						rateTesterWizardOpen: false,
						rates: [],
					}));
				}}
				organization={props.selectedOrganization}
				site={props.selectedSite}
				mode={state.mode}
				rate={state.rate}
				groups={state.groups}
				beacons={beacons.map((beacon) => ({
					value: beacon.BeaconID,
					label: beacon.BeaconLocation,
				}))}
			/>
		);
	}

	const periods = [
		{ value: "None", label: "None" },
		{ value: "12Hours", label: "12 Hours" },
		{ value: "24Hours", label: "24 Hours" },
	];

	const gracePeriods = [
		{ value: null, label: "No grace period" },
		{ value: 60, label: "1 Minute" },
		{ value: 300, label: "5 Minutes" },
		{ value: 600, label: "10 Minutes" },
		{ value: 900, label: "15 Minutes" },
	];

	const sessionDurations = [
		{ value: null, label: "Unlimited" },
		{ value: 60, label: "1 Hour" },
		{ value: 60 * 2, label: "2 Hours" },
		{ value: 60 * 3, label: "3 Hours" },
		{ value: 60 * 4, label: "4 Hours" },
		{ value: 60 * 5, label: "5 Hours" },
		{ value: 60 * 6, label: "6 Hours" },
		{ value: 60 * 7, label: "7 Hours" },
		{ value: 60 * 8, label: "8 Hours" },
		{ value: 60 * 9, label: "9 Hours" },
		{ value: 60 * 10, label: "10 Hours" },
		{ value: 60 * 11, label: "11 Hours" },
		{ value: 60 * 12, label: "12 Hours" },
		{ value: 60 * 13, label: "13 Hours" },
		{ value: 60 * 14, label: "14 Hours" },
		{ value: 60 * 15, label: "15 Hours" },
		{ value: 60 * 16, label: "16 Hours" },
		{ value: 60 * 17, label: "17 Hours" },
		{ value: 60 * 18, label: "18 Hours" },
		{ value: 60 * 19, label: "19 Hours" },
		{ value: 60 * 20, label: "20 Hours" },
		{ value: 60 * 21, label: "21 Hours" },
		{ value: 60 * 22, label: "22 Hours" },
		{ value: 60 * 23, label: "23 Hours" },
		{ value: 60 * 24, label: "24 Hours" },
	];

	// Reformat rates into a format that the dropdown component can understand
	// Also remove any rates that have a RootRateID, as these are not the base rates
	const nominated = (rates || [])
		.filter((rate) => !rate.RootRateID)
		.map((rate) => ({
			value: rate.RateID,
			label: rate.Name,
		}));

	const schema = Yup.object().shape({
		feeCapType: Yup.string(),
		feeCapAmount: Yup.number().nullable(),
		feeCapRateIds: Yup.string(),
		gracePeriod: Yup.string().nullable(),
		SessionDurationConfiguration: Yup.mixed().when(
			[
				"duration",
				"durationType",
				"minimumDuration",
				"maximumDuration",
				"maximumRestrictionType",
				"minimumRestrictionType",
			],
			{
				is: (
					duration,
					durationType,
					minimumDuration,
					maximumDuration,
					maximumRestrictionType,
					minimumRestrictionType
				) => {
					if (!SessionConfigurationAccess) return false;
					const maxDurationValue = convertToMinutes({
						duration: maximumDuration?.value ?? maximumDuration,
						durationType: maximumRestrictionType?.value,
					});
					const minDurationValue = convertToMinutes({
						duration: minimumDuration?.value ?? minimumDuration,
						durationType: minimumRestrictionType?.value,
					});
					const durationValue = convertToMinutes({
						duration: duration?.value ?? duration,
						durationType: durationType?.value,
					});

					const isGreaterThanMinimum = maxDurationValue < minDurationValue;

					const isIncrementIsGreaterThanMinimum =
						minDurationValue < durationValue;

					return (
						(minimumDuration !== null &&
							maximumDuration !== null &&
							isGreaterThanMinimum) ||
						isIncrementIsGreaterThanMinimum
					);
				},
				then: Yup.number().required(),
				otherwise: Yup.number().nullable(),
			}
		),
	});

	const durationHandler = ({ value, setFieldValue, type, field }) => {
		const { min, max } = ["maximumDuration", "minimumDuration"].includes(field)
			? DURATION_MAXIMUM_RESTRICTION_CONFIG[type]
			: DURATION_CONFIG[type];
		const currentValue = parseInt(value);
		if (currentValue >= min && currentValue <= max) {
			setFieldValue(
				field,
				["Day"].includes(type) ? Math.round(currentValue) : currentValue
			);
		}
	};

	const setDefaultDuration = (durationType, setFieldValue) => {
		const { default: defaultValue } = DURATION_CONFIG[durationType?.value];
		if (defaultValue) {
			setFieldValue("duration", defaultValue);
			setFieldValue("minimumDuration", defaultValue);
			setFieldValue("minimumRestrictionType", durationType);

			const isDay = ["Day"].includes(durationType.value);
			const isMinutesOrHours = ["Minutes", "Hours"].includes(
				durationType.value
			);

			setFieldValue(
				"maximumDuration",
				isDay
					? DURATIONS.MAXIMUM_DAYS_LIMIT
					: isMinutesOrHours
					? DURATIONS.MAX_HOURS_LIMIT
					: ""
			);
			setFieldValue(
				"maximumRestrictionType",
				isDay
					? durationType
					: isMinutesOrHours
					? durationTypes.find((e) => ["Hours"].includes(e?.value))
					: null
			);
		}
	};

	return (
		<div>
			<PageTitle>Transient Rates</PageTitle>
			<PageActions>
				<LeftActions>
					<div style={{ width: "240px", marginRight: "10px" }}>
						<Dropdown
							options={props.availableSites
								.filter((site) => site.SecureParkingExternalID === null)
								.map((site) => ({
									value: site.SiteID,
									label: site.IsDeactivated
										? `${site.Name} (Deactivated)`
										: site.Name,
									...site,
								}))}
							value={
								props.selectedSite && {
									...props.selectedSite,
									value: props.selectedSite.SiteID,
									label: props.selectedSite.Name,
								}
							}
							onChange={(site) => {
								setState((_state) => ({
									..._state,
									groupId: null,
								}));

								setSite(site);
							}}
						/>
					</div>

					<div style={{ width: "240px", marginRight: "10px" }}>
						<Dropdown
							options={[{ label: "No group (public)" }, ...state.groups]}
							value={
								state.groups.find((group) => group.value === state.groupId) || {
									label: "No group (public)",
								}
							}
							onChange={(group) => {
								setState((_state) => ({
									..._state,
									groupId: group.value,
								}));
							}}
						/>
					</div>
				</LeftActions>
				<RightActions>
					{canManageRates &&
						!(props.selectedSite && props.selectedSite.IsDeactivated) && (
							<Button
								style={{ marginRight: "10px" }}
								color="blue"
								onClick={() => openRateWizard({}, "add")}
							>
								<Plus size={20} />
								Add Rate
							</Button>
						)}
					{/* <Button style={{ marginRight: "10px" }} color="blue">
						Copy Rate
					</Button> */}
					<Button color="blue" onClick={() => openRateTesterWizard()}>
						Test Rates
					</Button>
				</RightActions>
			</PageActions>

			<FormLayout
				enableReinitialize={true}
				initialValues={{
					feeCapType: props.selectedSite ? props.selectedSite.FeeCapType : null,
					feeCapAmount: props.selectedSite
						? props.selectedSite.FeeCapAmount
						: null,
					feeCapRateIds: props.selectedSite
						? props.selectedSite.FeeCapRateIDs
						: null,
					gracePeriod: props.selectedSite
						? props.selectedSite.GracePeriodSeconds
						: null,
					maxSessions: props.selectedSite
						? props.selectedSite.Configuration?.MaxSessions
						: null,
					maxSessionDuration: props.selectedSite
						? props.selectedSite.Configuration?.MaxSessionDuration
						: null,
					durationType:
						durationTypes?.find(
							(e) =>
								e.value ===
								props.selectedSite?.Configuration?.SessionDurationConfiguration
									?.Duration?.DurationType
						) ?? null,
					duration: ["minutes"].includes(
						props.selectedSite?.Configuration?.SessionDurationConfiguration?.Duration?.DurationType?.toLocaleLowerCase()
					)
						? durationTimeInMinutes?.find(
								(e) =>
									e.value ===
									+props.selectedSite?.Configuration
										?.SessionDurationConfiguration?.Duration?.Duration
						  )
						: props.selectedSite?.Configuration?.SessionDurationConfiguration
								?.Duration?.Duration ?? null,
					minimumDuration: ["minutes"].includes(
						props.selectedSite?.Configuration?.SessionDurationConfiguration?.DurationRestriction?.MinimumRestrictionType?.toLocaleLowerCase()
					)
						? durationTimeInMinutes?.find(
								(e) =>
									e.value ===
									+props.selectedSite?.Configuration
										?.SessionDurationConfiguration?.DurationRestriction
										?.MinimumDuration
						  )
						: +props.selectedSite?.Configuration?.SessionDurationConfiguration
								?.DurationRestriction?.MinimumDuration ?? null,
					maximumDuration: ["minutes"].includes(
						props.selectedSite?.Configuration?.SessionDurationConfiguration?.DurationRestriction?.MaximumRestrictionType?.toLocaleLowerCase()
					)
						? durationTimeInMinutes?.find(
								(e) =>
									e.value ===
									+props.selectedSite?.Configuration
										?.SessionDurationConfiguration?.DurationRestriction
										?.MaximumDuration
						  )
						: +props.selectedSite?.Configuration?.SessionDurationConfiguration
								?.DurationRestriction?.MaximumDuration ?? null,
					minimumRestrictionType:
						durationTypes?.find(
							(e) =>
								e.value ===
								props.selectedSite?.Configuration?.SessionDurationConfiguration
									?.DurationRestriction?.MinimumRestrictionType
						) ?? null,
					maximumRestrictionType:
						durationTypes?.find(
							(e) =>
								e.value ===
								props.selectedSite?.Configuration?.SessionDurationConfiguration
									?.DurationRestriction?.MaximumRestrictionType
						) ?? null,
				}}
				validationSchema={schema}
				onSubmit={function (values, { setSubmitting }) {
					setSubmitting(true);
					if (props.selectedSite && props.selectedSite.SiteID) {
						const data = {
							FeeCapAmount: values.feeCapAmount,
							FeeCapRateIDs: values.feeCapRateIds,
							FeeCapType: values.feeCapType,
							GracePeriodSeconds: values.gracePeriod,
							MaxSessions: values.maxSessions,
							MaxSessionDuration: values.maxSessionDuration,
						};
						const request = SessionConfigurationAccess
							? {
									...data,
									SessionDurationConfiguration: JSON.stringify({
										Duration: {
											DurationType: values.durationType?.value,
											Duration: values.duration?.value ?? values.duration,
										},
										DurationRestriction: {
											MinimumRestrictionType:
												values?.minimumRestrictionType?.value,
											MinimumDuration:
												values.minimumDuration?.value ?? values.minimumDuration,
											MaximumRestrictionType:
												values?.maximumRestrictionType?.value,
											MaximumDuration:
												values.maximumDuration?.value ?? values.maximumDuration,
										},
									}),
							  }
							: {
									...data,
							  };
						installationApi
							.updateSite(props.selectedSite.SiteID, {
								...request,
							})
							.then(() => {
								Alert.success("Settings saved");
								getAvailableSites();
							});
					} else {
						Alert.error("Please select a site");
					}

					setSubmitting(false);
				}}
				render={({ values, handleSubmit, setFieldValue }) => (
					<form
						className="form"
						onSubmit={(event) => {
							event.preventDefault();
						}}
					>
						<Card>
							<Accordion title="Site Session Settings" expanded={false}>
								<Grid $sessionDuration={SessionConfigurationAccess}>
									<FieldWrapper>
										<Label>Maximum rate period</Label>
										<FieldContent>
											<Dropdown
												options={periods}
												isDisabled={!canManageRates}
												value={periods.find(
													(e) => e.value === values.feeCapType
												)}
												onChange={(period) => {
													setFieldValue("feeCapType", period.value);
												}}
											/>
										</FieldContent>
									</FieldWrapper>
									<FieldWrapper>
										<Label>Maximum session duration</Label>
										<SubLabel>
											The setting does not affect gated or ANPR sessions
										</SubLabel>
										<FieldContent>
											<Dropdown
												options={sessionDurations}
												isDisabled={!canManageRates}
												value={sessionDurations.find(
													(e) => e.value === values.maxSessionDuration
												)}
												onChange={(maxSessionDuration) => {
													setFieldValue(
														"maxSessionDuration",
														maxSessionDuration.value
													);
												}}
											/>
										</FieldContent>
									</FieldWrapper>
									<FieldWrapper hidden={!SessionConfigurationAccess}>
										<Label>Duration Increment</Label>
										<SubLabel>
											This setting does not affect ANPR freeflow sessions
										</SubLabel>
										<Grid
											$sessionDuration={!SessionConfigurationAccess}
											style={{ height: "2rem" }}
										>
											<div>
												<Dropdown
													isMulti={false}
													options={durationTypes}
													value={values.durationType}
													onChange={(_duration) => {
														setFieldValue("durationType", _duration);
														setDefaultDuration(_duration, setFieldValue);
													}}
												/>
											</div>

											{!["minutes"].includes(
												values.durationType?.value.toLocaleLowerCase()
											) ? (
												<IncrementWrapper>
													<EditableInputField
														type="number"
														name="duration"
														value={
															values.duration === null ? "" : values.duration
														}
														onChange={(event) => {
															durationHandler({
																value: event.target.value,
																setFieldValue,
																type: values.durationType?.value,
																field: "duration",
															});
														}}
													/>
												</IncrementWrapper>
											) : (
												<>
													<Dropdown
														isMulti={false}
														options={durationTimeInMinutes}
														value={values.duration}
														onChange={(_duration) => {
															setFieldValue("duration", _duration);
															setErrorMessage((prevValue) => ({
																...prevValue,
																incrementError:
																	convertToMinutes({
																		duration:
																			values.minimumDuration?.value ??
																			values.minimumDuration,
																		durationType:
																			values?.minimumRestrictionType?.value,
																	}) <
																	convertToMinutes({
																		duration: _duration.value ?? _duration,
																		durationType: _duration?.value,
																	})
																		? "The minimum duration must be greater or equal than the increment duration"
																		: "",
															}));
														}}
													/>
												</>
											)}
										</Grid>
									</FieldWrapper>
									<FieldWrapper>
										<Label>Maximum amount per period</Label>
										<FieldContent>
											<EditableInputField
												type="number"
												name="feeCapAmount"
												value={
													values.feeCapAmount === null
														? ""
														: values.feeCapAmount
												}
												onChange={(event) => {
													setFieldValue(
														"feeCapAmount",
														event.target.value === 0
															? 0
															: event.target.value || null
													);
												}}
												disabled={!canManageRates}
											/>
										</FieldContent>
									</FieldWrapper>
									<FieldWrapper>
										<Label>Maximum number of sessions per day</Label>
										<SubLabel>
											The setting does not affect gated or ANPR sessions
										</SubLabel>
										<FieldContent>
											<EditableInputField
												type="number"
												name="maxSessions"
												value={
													values.maxSessions === null ? "" : values.maxSessions
												}
												onChange={(event) => {
													setFieldValue(
														"maxSessions",
														event.target.value === 0
															? 0
															: event.target.value || null
													);
												}}
												disabled={!canManageRates}
											/>
										</FieldContent>
									</FieldWrapper>
									<FieldWrapper hidden={!SessionConfigurationAccess}>
										<Label>Duration Restriction</Label>

										<SubLabel>
											This setting does not affect ANPR freeflow sessions
										</SubLabel>
										<DurationWrapper>
											<SubLabel>Minimum Duration</SubLabel>
											<IncrementWrapper>
												{!["minutes"].includes(
													values.minimumRestrictionType?.value?.toLocaleLowerCase()
												) ? (
													<EditableInputField
														type="number"
														name="minimumDuration"
														value={
															values.minimumDuration === null
																? ""
																: values.minimumDuration
														}
														error={
															convertToMinutes({
																duration:
																	values.minimumDuration?.value ??
																	values.minimumDuration,
																durationType:
																	values?.minimumRestrictionType?.value,
															}) <
															convertToMinutes({
																duration:
																	values.duration?.value ?? values.duration,
																durationType: values?.durationType?.value,
															})
																? "The minimum duration must be greater or equal than the increment duration"
																: null
														}
														onChange={(event) => {
															durationHandler({
																value: event.target.value,
																setFieldValue,
																type: values.minimumRestrictionType?.value,
																field: "minimumDuration",
																restrictionType: "minimumRestrictionType",
															});
														}}
													/>
												) : (
													<>
														<Dropdown
															isMulti={false}
															options={durationTimeInMinutes}
															value={values.minimumDuration}
															onChange={(_duration) => {
																setFieldValue("minimumDuration", _duration);
																setErrorMessage((prevValue) => ({
																	...prevValue,
																	incrementError:
																		convertToMinutes({
																			duration:
																				_duration.value ??
																				_duration.minimumDuration,
																			durationType: _duration?.value,
																		}) <
																		convertToMinutes({
																			duration:
																				values.duration?.value ??
																				values.duration,
																			durationType: values?.durationType?.value,
																		})
																			? "The minimum duration must be greater or equal than the increment duration"
																			: "",
																}));
															}}
														/>
														{errorMessage?.incrementError && (
															<ErrorMessage>
																<div>
																	<AlertCircle icon="alert-circle" size={18} />
																</div>
																<p>{errorMessage?.incrementError}</p>
															</ErrorMessage>
														)}
													</>
												)}
											</IncrementWrapper>

											<Dropdown
												isMulti={false}
												options={durationTypes}
												value={values.minimumRestrictionType}
												onChange={(_duration) => {
													setFieldValue("minimumRestrictionType", _duration);
												}}
											/>
											<SubLabel>Maximum Duration</SubLabel>
											<IncrementWrapper>
												{values.maximumRestrictionType?.value?.toLocaleLowerCase() !==
												"minutes" ? (
													<EditableInputField
														type="number"
														name="maximumDuration"
														value={
															values.maximumDuration === null
																? ""
																: values.maximumDuration
														}
														error={
															convertToMinutes({
																duration:
																	values.maximumDuration?.value ??
																	values.maximumDuration,
																durationType:
																	values?.maximumRestrictionType?.value,
															}) <
															convertToMinutes({
																duration:
																	values.minimumDuration?.value ??
																	values.minimumDuration,
																durationType:
																	values?.minimumRestrictionType?.value,
															})
																? "Set a Valid Maximum Duration"
																: null
														}
														onChange={(event) => {
															durationHandler({
																value: event.target.value,
																setFieldValue,
																type: values.maximumRestrictionType?.value,
																field: "maximumDuration",
																restrictionType: "maximumRestrictionType",
															});
														}}
													/>
												) : (
													<>
														<Dropdown
															isMulti={false}
															options={durationTimeInMinutes}
															value={values.maximumDuration}
															onChange={(_duration) => {
																setFieldValue("maximumDuration", _duration);
																setErrorMessage((prevValue) => {
																	const maxDurationValue = convertToMinutes({
																		duration:
																			_duration?.value ??
																			_duration.maximumDuration,
																		durationType:
																			values.maximumRestrictionType?.value,
																	});
																	const minDurationValue = convertToMinutes({
																		duration:
																			values.minimumDuration?.value ??
																			values.minimumDuration,
																		durationType:
																			values.minimumRestrictionType?.value,
																	});

																	const isGreaterThanMinimum =
																		maxDurationValue < minDurationValue;
																	return {
																		...prevValue,
																		minimumError: isGreaterThanMinimum
																			? "Set a Valid Maximum Duration"
																			: "",
																	};
																});
															}}
														/>
														{errorMessage?.minimumError && (
															<ErrorMessage>
																<div>
																	<AlertCircle icon="alert-circle" size={18} />
																</div>
																<p>{errorMessage?.minimumError}</p>
															</ErrorMessage>
														)}
													</>
												)}
											</IncrementWrapper>
											<Dropdown
												isMulti={false}
												options={getFilteredDurationTypes(
													values?.durationType,
													values?.minimumRestrictionType
												)}
												value={values.maximumRestrictionType}
												onChange={(_duration) => {
													setFieldValue(
														"maximumRestrictionTypeValue",
														_duration?.value
													);
													setFieldValue("maximumRestrictionType", _duration);
													setFieldValue(
														"maximumDuration",
														DURATION_MAXIMUM_RESTRICTION_CONFIG[
															_duration?.value
														].max
													);
												}}
											/>
										</DurationWrapper>
									</FieldWrapper>

									<FieldWrapper>
										<Label>Nominated Rates</Label>
										<FieldContent>
											<Dropdown
												isDisabled={!canManageRates}
												isMulti={true}
												options={nominated}
												value={nominated.filter((rate) =>
													JSON.parse(values.feeCapRateIds || "[]").includes(
														rate.value
													)
												)}
												onChange={(_nominated) =>
													setFieldValue(
														"feeCapRateIds",
														JSON.stringify(
															(_nominated || []).map((rate) => rate.value)
														)
													)
												}
											/>
										</FieldContent>
									</FieldWrapper>

									<FieldWrapper>
										<Label>Grace Period</Label>
										<FieldContent>
											<Dropdown
												options={gracePeriods}
												isDisabled={!canManageRates}
												value={gracePeriods.find(
													(e) => e.value === values.gracePeriod
												)}
												onChange={(gracePeriod) => {
													setFieldValue("gracePeriod", gracePeriod.value);
												}}
											/>
										</FieldContent>
									</FieldWrapper>
								</Grid>
								<FieldWrapper>
									{canManageRates && (
										<Button color="blue" onClick={handleSubmit}>
											Update
										</Button>
									)}
								</FieldWrapper>
							</Accordion>
						</Card>
					</form>
				)}
			/>
			{isLoading ? (
				<LoadingPlaceholder />
			) : (
				<Card>
					<Calendar
						localizer={localizer}
						events={state.events || []}
						startAccessor="start"
						endAccessor="end"
						defaultDate={state.currentDate}
						defaultView={Views.WEEK}
						eventPropGetter={eventStyleGetter}
						onSelectEvent={(event) => openRateWizard(event.rate, "update")}
						views={[Views.WEEK]}
						onNavigate={(value) => getWeeklyEvents(value, state.rates)}
						formats={{
							dayRangeHeaderFormat: ({ start, end }) => {
								return (
									moment(start).format("DD MMM YYYY") +
									" - " +
									moment(end).format("DD MMM YYYY")
								);
							},
						}}
						components={{
							event: (event) => (
								<span data-event={JSON.stringify(event)}>{event.title}</span>
							),
						}}
					/>
				</Card>
			)}
		</div>
	);
}
